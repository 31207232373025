import React from "react";
import { Link } from "react-router-dom";

const BlogSidebar = () => {
  return (
    <div className="sidebar__wrapper">
      <div className="sidebar__widget mb-40">
        <h3 className="sidebar__widget-title">Recent Post</h3>
        <div className="sidebar__widget-content">
          <div className="sidebar__post rc__post">


          <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/why-xylem-pumps-best-for-water-treatment">
                  <img src="assets/img/blog/xylem-pumps-water-treatment.jpg" alt="Xylem Pumps Distributors" title="Xylem Pumps Distributors" />
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>22 March 2025</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/why-xylem-pumps-best-for-water-treatment">
                  Why Xylem Pumps Are the Preferred Choice for Water Treatment Plants
                  </Link>
                </h3>
              </div>
            </div>


          <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/Tsurumi-Submersible-Pumps-Reliable-for-Tough-Jobs">
                  <img src="assets/img/blog/Tsurumi-submersible-pumps.jpg" alt="tsurumi submersible pump, tsurumi pump distributors, tsurumi pump, tsurumi pump dealers" title="tsurumi submersible pump" />
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>22 March. 2025</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/Tsurumi-Submersible-Pumps-Reliable-for-Tough-Jobs">
                  Tsurumi Submersible Pumps: A Reliable Solution for Tough Environments 
                  </Link>
                </h3>
              </div>
            </div>


          <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/Why-Tsurumi-Pump-Distributors-Lead-in-Industry-Use">
                  <img src="assets/img/blog/tsurumi-pump-distributors.jpg" alt="tsurumi pump distributors, tsurumi pump, tsurumi pump dealers,tsurumi submersible pump" title="tsurumi pump distributors" />
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>18 March. 2025</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/Why-Tsurumi-Pump-Distributors-Lead-in-Industry-Use">
                  Why Are Tsurumi Pump Distributors the First Choice for Industrial Applications?
                  </Link>
                </h3>
              </div>
            </div>


            <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/Tsurumi-Submersible-Pump-Excel-in-Heavy-Duty-Use">
                  <img src="assets/img/blog/tsurumi-submersible-pump.jpg" alt="tsurumi submersible pump, tsurumi pump distributors, tsurumi pump dealers,tsurumi pump" title="tsurumi submersible pump" />
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>15 March. 2025</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/Tsurumi-Submersible-Pump-Excel-in-Heavy-Duty-Use">
                  What Makes Tsurumi Submersible Pumps Ideal for Heavy-Duty Applications?
                  </Link>
                </h3>
              </div>
            </div>

            <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/Why-Xylem-Pumps-Are-Ideal-for-Municipal-Water-Project">
                  <img src="assets/img/blog/xylem-pumps.jpg" alt="xylem pumps, xylem pumps distributors,xylem distributors" title="xylem pumps"/>
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>15 March. 2025</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/Why-Xylem-Pumps-Are-Ideal-for-Municipal-Water-Project">
                  Why Are Xylem Pumps Preferred for Municipal Water Projects?
                  </Link>
                </h3>
              </div>
            </div>


            <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/Boost-Energy-Efficiency-with-Xylem-Pumps">
                  <img src="assets/img/blog/Boost-Energy-Efficiency-with-Xylem-Pumps.jpg" alt="xylem pumps, xylem pumps distributors,xylem distributors" title="xylem pumps"/>
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>15 March. 2025</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/Boost-Energy-Efficiency-with-Xylem-Pumps">
                  How Can Xylem Pumps Improve Energy Efficiency in Water Systems?
                  </Link>
                </h3>
              </div>
            </div>

            <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/Tsurumi-Pumps-Reliable-Wastewater-Solutions">
                  <img src="assets/img/blog/tsurumi-pump.jpg" alt="tsurumi pump, tsurumi pump distributors, tsurumi pump dealers,tsurumi submersible pump" title="tsurumi pump"/>
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>15 March. 2025</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/Tsurumi-Pumps-Reliable-Wastewater-Solutions">
                  How Do Tsurumi Pumps Support Wastewater and Drainage Solutions?
                  </Link>
                </h3>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
